.homepage-highlight {
    background-image: url('./assets/img/homepage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 55vh;
}

.homepage-body {
    background: white;
    border-top: 5px solid #343a40;
}

.homepage-body .header-box {
    position: relative;
    top: -1em;
    font-size: 5rem;
    text-align: center;
}

.homepage-body .sub-header {
    margin-top: -1em;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;
}

.homepage-body .sub-header-alert {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 3rem;
}

.homepage-body .button-container {
    text-align: center;
    margin-bottom: 2rem;
}

.homepage .container {
    margin-top: 30px;
}

.header-box-span {
    background-color: white;
    color: #343a40;
    border: 5px solid #343a40;
    border-radius: 4px;
    padding: 15px;
    display: inline-block;
    position: relative;
    margin-top: -2em;
}

.spacerForSpinner {
    min-height: 4rem;
    line-height: 4rem;
}

@keyframes absoluteFadeOut {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.9;
    }
    40% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.3;
    }
    80% {
        opacity: 0.1;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@-webkit-keyframes absoluteFadeOut {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 0.9;
    }
    40% {
        opacity: 0.5;
    }
    60% {
        opacity: 0.3;
    }
    80% {
        opacity: 0.1;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
